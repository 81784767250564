var _ = require("underscore");
$.fn.uiFilmstrip = function (options) {
    options = $.extend({scrollTimeout: 700, autoPlay: $(this).data("autoplay")}, options);
    return $(this).each(function () {
        var elm = this, $elm = $(elm);
        var $content = $(".ui-filmstrip-scroll", elm);
        var childWidth = $content.children().eq(0).width();
        var minWidth = 0;
        if ($elm.data("minwidth")) {
            minWidth = $elm.data("minwidth");
        }

        var w = 0, h = 0, tw = 0, cContent = null;

        cContent = $content.clone(true).addClass("cloned");
        cContent.hide().children();
        $content.after(cContent);
        function updateWidth() {
            if (!$elm.find(".ui-filmstrip-content").get(0).ownerDocument.defaultView) {
                return;
            }
            if (!childWidth) {
                childWidth = $content.children().eq(0).width();
            }
            var cw = $elm.find(".ui-filmstrip-content").width(),
                cs = $content.children().size();
            var a1 = 0;
            if (childWidth < cw) {
                a1 = Math.floor(cw / Math.round(cw / childWidth))
            }
            if (a1 * cs < cw && cs > 0 && a1 > 0) {
                a1 = Math.round(cw / cs);
            }
            if (minWidth > 0 && a1 < minWidth && minWidth < cw && a1 > 0) {
                a1 = Math.round(cw / Math.floor(cw / minWidth));
            }
            if (a1 <= 0) {
                a1 = cw;
            }
            $content.children().css({width: a1 + "px"});
            cContent.children().css({width: a1 + "px"});
            w = 0;
            tw = 0;
            h = 0;
            $content.children().css({height: ""}).each(function () {
                var t1 = $(this).outerWidth(true);
                if (t1 > tw) {
                    tw = t1;
                }
                var h1 = $(this).outerHeight(true);
                if (h1 > h) {
                    h = h1;
                }
                w += t1;
            });

            $content.css({width: w + "px"});
            cContent.css({width: w + "px"});
            $elm.find(".ui-filmstrip-content").css({height: h + "px"});

            var l1 = $content.position().left;
            var l2 = cContent.position().left;
            if (l1 > l2) {
                cContent.css({left: l1 - w + "px"});
            } else {
                cContent.css({left: l1 + w + "px"});
            }
            cContent.show();
            updateX(0);
        }

        $content.find("img").load(function () {
            updateWidth();
        }).imageSize();


        var x = 0, t1 = 0;

        function updateX(speed) {
            if (_.isUndefined(speed)) {
                speed = options.scrollTimeout / 1000;
            }
            TweenLite.killTweensOf($content);
            TweenLite.killTweensOf(cContent);
            var w0 = $content.children().size();
            var w1 = Math.round($elm.find(".ui-filmstrip-content").width() / tw);
            if (x < -w0) {
                x = -w0;
            } else if (x > w1) {
                x = w1;
            }
            var l = tw * x, l2, l1 = $content.position().left;
            if (l >= 0 && l1 >= 0) {
                l2 = tw * (x - w0);
                cContent.css({left: l1 - w + "px"});
            } else {
                l2 = tw * (x + w0);
                cContent.css({left: l1 + w + "px"});
            }


            TweenLite.to($content[0], speed, {
                css: {left: l + "px"},
                ease: Power2.easeIn, onComplete: function () {
                    if (x + w0 == 0) {
                        x = 0;
                        $content.css({left: l2 + "px"});
                    } else if (x - w1 == 0) {
                        x = w1 - w0;
                        $content.css({left: l2 + "px"});
                    }

                }
            });
            TweenLite.to(cContent[0], speed, {
                css: {left: l2 + "px"},
                ease: Power2.easeIn, onComplete: function () {
                    l = $content.position().left;
                    if (l > 0) {
                        cContent.css({left: l - w + "px"});
                    } else {
                        cContent.css({left: l + w + "px"});
                    }
                }
            });

        }

        if (false) {
            $elm.find(".ui-filmstrip-content").on("mousewheel DOMMouseScroll", function (e) {
                var delta = e.originalEvent.detail < 0 || e.originalEvent.wheelDelta > 0 ? 1 : -1;
                if (t1 < $.now() - options.scrollTimeout) {
                    x += delta;
                    updateX();
                    t1 = $.now();
                }
                e.preventDefault();
            });
        }

        Hammer($elm.find(".ui-filmstrip-left").get(0)).on("tap", function (e) {
            x++;
            updateX();
        });
        Hammer($elm.find(".ui-filmstrip-right").get(0)).on("tap", function (e) {
            x--;
            updateX();
        });
        function dragMove(l1) {
            $content.css({left: tw * x + l1 + "px"});
            if (l1 > 0) {
                cContent.css({left: tw * x + l1 - w + "px"});
            } else {
                cContent.css({left: tw * x + l1 + w + "px"});
            }
        }

        function bindDrags(elm) {
            Hammer(elm).on("dragstart", function (e) {
                if (options.autoPlay) {
                    clearInterval(apInterval);
                }
            }).on("dragright", function (e) {
                e.gesture.preventDefault();
                dragMove(e.gesture.deltaX);
            }).on("dragleft", function (e) {
                e.gesture.preventDefault();
                dragMove(e.gesture.deltaX);
            }).on("dragend", function (e) {
                var l = $content.position().left;
                x = Math.round(l / tw);
                updateX();
            });
        }

        if (options.isTpl !== true){
            bindDrags($content.get(0));
            bindDrags(cContent.get(0));
        }


        var apInterval;
        if (options.autoPlay && options.isTpl !== true) {
            function autoMove() {
                x--;
                updateX();
            }
            apInterval = setInterval(autoMove, options.autoPlay);

//            $elm.on({
//                mouseenter: function () {
//
//                },
//                mouseleave: function () {
//                    apInterval = setInterval(autoMove, options.autoPlay);
//                }
//            });
        }

        $(window).resize(updateWidth);
        if ($elm.is(":hidden")) {
            $elm.addClass("show");
        } else {
            updateWidth();
        }
        $elm.on({
            updateUi: function () {
                updateWidth();
            },
            show: function () {
                // $elm.removeClass("show");
                updateWidth();
            },
            destroy: function () {
                if (apInterval){
                    clearInterval(apInterval);
                }

                $(window).off({resize: updateWidth});

            }
        }).addClass("destroy");
    });
};

